export default {
	messages: {
		ru: {
          'Дивіться': 'Смотрите',
          'як ми разом змінили': 'как мы вместе изменили',
          'авторинок': 'авторынок',
          'у': 'в',
          'Правда — найважливіше, що є': 'Правда – самое важное, что есть',
          'Це доводять тисячі покупців, які перевіряють автівки та діляться правдою з іншими': 'Это доказывают тысячи покупателей, которые проверяют автомобили и делятся правдой с другими',
          'І продавці, які чесно пишуть про своє авто': 'И продавцы, которые честно пишут о своем авто',
          'Так ми робимо наш авторинок перевіреним та безпечним': 'Так мы делаем наш авторынок проверенным и безопасным',
          'Просто погляньте, що ми': 'Просто посмотрите, что мы',
          'уже зробили разом': 'уже сделали вместе',
          'за цей рік': 'за этот год',
          'Перевірено': 'Проверено',
          'пропозицій перевірили': 'предложений проверили',
          'Роботи-інспектори та Кібер-інспектори AUTO.RIA ще до того, як вони потрапили в пошук': 'Роботы-инспекторы и Кибер-инспекторы AUTO.RIA еще до того, как они попали в поиск',
          'авто перевірено по VIN-коду за реєстрами МВС': 'авто проверено по VIN-коду по реестрам МВД',
          'базами даних дилерів, банків, страхових та завдяки чесним продавцям': 'базами данных дилеров, банков, страховых и благодаря честным продавцам',
          'Перевірено людьми': 'Проверено людьми',
          'українців у спільноті': 'украинцев в сообществе',
          'долучилися': 'присоединились',
          'до спільноти за рік': 'к сообществу за год',
          'За кожним Інтернет-Інспектором стоїть': 'За каждым Интернет-инспектором стоит',
          'щонайменше один відгук': 'по меньшей мере один отзыв',
          'щонайменше один коментар': 'по меньшей мере один комментарий',
          'сигнал про чесність продавця чи приховування правди': 'сигнал о честности продавца или сокрытии правды',
          'Інтернет-Інспектори': 'Интернет-инспекторы',
          'залишили': 'оставили',
          'відгуків про авто': 'отзывов об авто',
          'коментарів про авто': 'комментариев об авто',
          'продавців': 'продавцов',
          'Кібер-інспектори AUTO.RIA ретельно перевіряють кожнісінький відгук': 'Кибер-инспекторы AUTO.RIA тщательно проверяют каждый отзыв',
          'Кібер-інспектори AUTO.RIA ретельно перевіряють кожнісінький коментар': 'Кибер-инспекторы AUTO.RIA тщательно проверяют каждый комментарий',
          'Кожен': 'Каждый',
          'Інспектор': 'Инспектор',
          'інспектує': 'инспектирует',
          'відгук підтверджується і допомагає купувати безпечно': 'отзыв подтверждается и помогает покупать безопасно',
          'коментар підтверджується і допомагає купувати безпечно': 'комментарий подтверждается и помогает покупать безопасно',
          'Перевірені коменти з’являються на сторінці авто, щоб доповнити опис від продавця. Або допомагають виправити опис авто, щоб все було чесно': 'Проверенные комменты появляются на странице авто, чтобы дополнить описание продавца. Или помогают исправить описание авто, чтобы все было честно',
          'А це все завдяки вам': 'А это все благодаря вам',
          'Дякуємо, що взяли і таки написали відгуки про авто та продавців': 'Спасибо, что взяли и написали отзывы об авто и продавцах',
          'Дякуємо, що взяли і таки написали коментарі про авто та продавців': 'Спасибо, что взяли и написали комментарии об авто и продавцах',
          'Чим Інтернет-Інспектори ділились найчастіше': 'Чем Интернет-инспекторы делились чаще всего',
          'Неточний опис авто чи фото': 'Неточное описание авто или фото',
          'Скручений пробіг': 'Скрученный пробег',
          'Авто після': 'Авто после',
          'Інші відгуки': 'Другие отзывы',
          'Інші коментарі': 'Другие комментарии',
          'Ось, що найбільше цінують у продавцях': 'Вот, что больше всего ценят в продавцах',
          'оцінок': 'оценок',
          'оцінки': 'оценки',
          'Продавець відкритий і ввічливий у спілкуванні': 'Продавец открыт и вежлив в общении',
          'Продавець вказав повну та чесну інформацію про авто в описі': 'Продавец указал полную и честную информацию об автомобиле в описании',
          'Продавець перевірив авто або погодився на додаткову перевірку': 'Продавец проверил автомобиль или согласился на дополнительную проверку',
          'найактивніших': 'самых активных',
          'балів': 'баллов',
          'Рейтинг Інтернет-інспекторів': 'Рейтинг Интернет-инспекторов',
          'Інтернет-інспекторів': 'Интернет-инспекторов',
          'Ваші досягнення у': 'Ваши достижения в',
          'Інтернет-інспектор': 'Интернет-инспектор',
          'рівня': 'уровня',
          'рівень': 'уровень',
          'Перевірок': 'Проверок',
          'Підтверджених': 'Подтвержденных',
          'Балів': 'Баллов',
          'Можу більше': 'Могу больше',
          'Хочете долучитись до цих супергероїв, щоб допомагати українцям купувати авто безпечно': 'Хотите приобщиться к этим супергероям, чтобы помогать украинцам покупать авто безопасно',
          'Це просто — діліться з людьми правдою': 'Это просто – делитесь с людьми правдой',
          'Я спробую': 'Я попытаюсь',
          'І як це було': 'И как это было',
          'Більше про Інтернет-Інспекторів': 'Больше об Интернет-инспекторах',
          'Срібний': 'Серебряный',
          'Золотий': 'Золотой',
          'Платиновий': 'Платиновый',
          'Бронзовий': 'Бронзовый',
          'Хто такі Інтернет-інспектори та як приєднатися до спільноти захисників правди читайте тут': 'Кто такие Интернет-инспекторы и как присоединиться к сообществу защитников правды читайте здесь',
          'Рік за роком AUTO.RIA створює нові перевірки авто та продавців, щоб кожен міг купувати автомобілі безпечно. А допомагають перевіряти десятки тисяч українців. Дивіться, чого ми досягли разом у 2021': 'Год за годом AUTO.RIA создает новые проверки авто и продавцов, чтобы каждый мог покупать автомобили безопасно. А помогают проверять десятки тысяч украинцев. Смотрите, чего мы достигли вместе в 2021 году',
          'Як ми разом змінили авторинок у 2021': 'Как мы вместе изменили авторынок в 2021 году',
		},
		uk: {
            'Дивіться': 'Дивіться',
            'як ми разом змінили': 'як ми разом змінили',
            'авторинок': 'авторинок',
            'у': 'у',
            'Правда — найважливіше, що є': 'Правда — найважливіше, що є',
            'Це доводять тисячі покупців, які перевіряють автівки та діляться правдою з іншими': 'Це доводять тисячі покупців, які перевіряють автівки та діляться правдою з іншими',
            'І продавці, які чесно пишуть про своє авто': 'І продавці, які чесно пишуть про своє авто',
            'Так ми робимо наш авторинок перевіреним та безпечним': 'Так ми робимо наш авторинок перевіреним та безпечним',
            'Просто погляньте, що ми': 'Просто погляньте, що ми',
            'уже зробили разом': 'уже зробили разом',
            'за цей рік': 'за цей рік',
            'Перевірено': 'Перевірено',
            'пропозицій перевірили': 'пропозицій перевірили',
            'Роботи-інспектори та Кібер-інспектори AUTO.RIA ще до того, як вони потрапили в пошук': 'Роботи-інспектори та Кібер-інспектори AUTO.RIA ще до того, як вони потрапили в пошук',
            'авто перевірено по VIN-коду за реєстрами МВС': 'авто перевірено по VIN-коду за реєстрами МВС',
            'базами даних дилерів, банків, страхових та завдяки чесним продавцям': 'базами даних дилерів, банків, страхових та завдяки чесним продавцям',
            'Перевірено людьми': 'Перевірено людьми',
            'українців у спільноті': 'українців у спільноті',
            'Інтернет-інспекторів долучилися': 'Інтернет-інспекторів долучилися',
            'до спільноти за рік': 'до спільноти за рік',
            'За кожним Інтернет-Інспектором стоїть': 'За кожним Інтернет-Інспектором стоїть',
            'щонайменше один відгук': 'щонайменше один відгук',
            'щонайменше один коментар': 'щонайменше один коментар',
            'сигнал про чесність продавця чи приховування правди': 'сигнал про чесність продавця чи приховування правди',
            'Інтернет-Інспектори': 'Інтернет-Інспектори',
            'залишили': 'залишили',
            'відгуків про авто': 'відгуків про авто',
            'коментарів про авто': 'коментарів про авто',
            'продавців': 'продавців',
            'Кібер-інспектори AUTO.RIA ретельно перевіряють кожнісінький відгук': 'Кібер-інспектори AUTO.RIA ретельно перевіряють кожнісінький відгук',
            'Кібер-інспектори AUTO.RIA ретельно перевіряють кожнісінький коментар': 'Кібер-інспектори AUTO.RIA ретельно перевіряють кожнісінький коментар',
            'Кожен': 'Кожен',
            'Інспектор': 'Інспектор',
            'інспектує': 'інспектує',
            'відгук підтверджується і допомагає купувати безпечно': 'відгук підтверджується і допомагає купувати безпечно',
            'коментар підтверджується і допомагає купувати безпечно': 'коментар підтверджується і допомагає купувати безпечно',
            'Перевірені коменти з’являються на сторінці авто, щоб доповнити опис від продавця. Або допомагають виправити опис авто, щоб все було чесно': 'Перевірені коменти з’являються на сторінці авто, щоб доповнити опис від продавця. Або допомагають виправити опис авто, щоб все було чесно',
            'А це все завдяки вам': 'А це все завдяки вам',
            'Дякуємо, що взяли і таки написали відгуки про авто та продавців': 'Дякуємо, що взяли і таки написали відгуки про авто та продавців',
            'Дякуємо, що взяли і таки написали коментарі про авто та продавців': 'Дякуємо, що взяли і таки написали коментарі про авто та продавців',
            'Чим Інтернет-Інспектори ділились найчастіше': 'Чим Інтернет-Інспектори ділились найчастіше',
            'Неточний опис авто чи фото': 'Неточний опис авто чи фото',
            'Скручений пробіг': 'Скручений пробіг',
            'Авто після': 'Авто після',
            'Інші відгуки': 'Інші відгуки',
            'Інші коментарі': 'Інші коментарі',
            'Ось, що найбільше цінують у продавцях': 'Ось, що найбільше цінують у продавцях',
            'оцінок': 'оцінок',
            'оцінки': 'оцінки',
            'Продавець відкритий і ввічливий у спілкуванні': 'Продавець відкритий і ввічливий у спілкуванні',
            'Продавець вказав повну та чесну інформацію про авто в описі': 'Продавець вказав повну та чесну інформацію про авто в описі',
            'Продавець перевірив авто або погодився на додаткову перевірку': 'Продавець перевірив авто або погодився на додаткову перевірку',
            'найактивніших': 'найактивніших',
            'балів': 'балів',
            'Рейтинг Інтернет-інспекторів': 'Рейтинг Інтернет-інспекторів',
            'Інтернет-інспекторів': 'Інтернет-інспекторів',
            'Ваші досягнення у': 'Ваші досягнення у',
            'Інтернет-інспектор': 'Інтернет-інспектор',
            'рівня': 'рівня',
            'рівень': 'рівень',
            'Перевірок': 'Перевірок',
            'Підтверджених': 'Підтверджених',
            'Балів': 'Балів',
            'Можу більше': 'Можу більше',
            'Хочете долучитись до цих супергероїв, щоб допомагати українцям купувати авто безпечно': 'Хочете долучитись до цих супергероїв, щоб допомагати українцям купувати авто безпечно',
            'Це просто — діліться з людьми правдою': 'Це просто — діліться з людьми правдою',
            'Я спробую': 'Я спробую',
            'І як це було': 'І як це було',
            'Більше про Інтернет-Інспекторів': 'Більше про Інтернет-Інспекторів',
            'Срібний': 'Срібний',
            'Золотий': 'Золотий',
            'Платиновий': 'Платиновий',
            'Бронзовий': 'Бронзовий',
            'Хто такі Інтернет-інспектори та як приєднатися до спільноти захисників правди читайте тут': 'Хто такі Інтернет-інспектори та як приєднатися до спільноти захисників правди читайте тут',
            'Рік за роком AUTO.RIA створює нові перевірки авто та продавців, щоб кожен міг купувати автомобілі безпечно. А допомагають перевіряти десятки тисяч українців. Дивіться, чого ми досягли разом у 2021': 'Рік за роком AUTO.RIA створює нові перевірки авто та продавців, щоб кожен міг купувати автомобілі безпечно. А допомагають перевіряти десятки тисяч українців. Дивіться, чого ми досягли разом у 2021',
            'Як ми разом змінили авторинок у 2021': 'Як ми разом змінили авторинок у 2021',
		},
	}
};
