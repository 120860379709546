import { mapGetters } from 'vuex';
const storeModule = require('../../store/Inspector');

export default {
    name: 'successInternetInspectors',
    init({store, context}) {
        store.registerModule('Inspector', storeModule);
        let cookies = {};
        if (context) {
            cookies = context.request.headers.cookie;
            return store.dispatch('Inspector/inspectorSuccessLanding', cookies)
        }
    },
    data() {
        return {
            description: this.$t('Рік за роком AUTO.RIA створює нові перевірки авто та продавців, щоб кожен міг купувати автомобілі безпечно. А допомагають перевіряти десятки тисяч українців. Дивіться, чого ми досягли разом у 2021'),
            title: this.$t('Як ми разом змінили авторинок у 2021'),
        }
    },
    computed: {
        ...mapGetters({
            data_: 'Inspector/getSuccessLandingData',
        }),
        data() {
            return this.data_ || {};
        },
        user() {
            return this.data.user || {};
        },
        userName() {
            return this.user.name || this.$t('Інспектор')
        },
        topInspectors() {
            // Тимчасово захардкодив дані про ТОП інспекторів,
            // щоб вони не злетіли коли будемо обнуляти інспекторів на Новий Рік
            const arr = [
                {
                    user: {
                        name: 'Вячеслав',
                        photo: 'https://css.riastatic.com/images/demo/avatar-24x24.svg',
                    },
                    rating: { level: 4, progress: 160020, start: '2020-09-21T19:47:11.362Z' },
                },
                {
                    user: {
                        name: 'Юрий',
                        photo: 'https://css.riastatic.com/images/demo/avatar-24x24.svg',
                    },
                    rating: { level: 4, progress: 130230, start: '2020-07-17T10:45:11.659Z' },
                },
                {
                    user: {
                        name: 'Юрій',
                        photo: 'https://cdn.riastatic.com/photos/avatars/all/792/79279/7927972/7927972m.jpg?v=1610889954',
                    },
                    rating: { level: 4, progress: 68250, start: '2020-12-27T14:34:07.482Z' },
                },
                {
                    user: {
                        name: '',
                        photo: 'https://css.riastatic.com/images/demo/avatar-24x24.svg',
                    },
                    rating: { level: 4, progress: 60340, start: '2020-05-15T12:07:39.016Z' },
                },
                {
                    user: {
                        name: 'Богдан',
                        photo: 'https://css.riastatic.com/images/demo/avatar-24x24.svg',
                    },
                    rating: { level: 4, progress: 48670, start: '2021-03-29T19:44:27.237Z' },
                },
                {
                    user: {
                        name: 'Михайло',
                        photo: 'https://css.riastatic.com/images/demo/avatar-24x24.svg',
                    },
                    rating: { level: 4, progress: 20350, start: '2021-03-19T18:15:55.523Z' },
                },
            ];
            // return this.data.topInspectors || [];
            return arr;
        },
        srcImgHead() {
            return `https://css.riastatic.com/images/demo/successInternetInspectors/headImg${this.langCode === 'ru' ? 'Ru' : 'Ukr'}.png`;
        },
        srcsetImgHead() {
            return `https://css.riastatic.com/images/demo/successInternetInspectors/headImg${this.langCode === 'ru' ? 'Ru' : 'Ukr'}.webp`;
        },
    },
    methods: {
        inspectorLabel(level) {
            switch (level) {
                case 2:
                    return {label: this.$t('Срібний'), shield: '_silver'};
                case 3:
                    return {label: this.$t('Золотий'), shield: '_gold'};
                case 4:
                    return {label: this.$t('Платиновий'), shield: '_platinum'};
                default:
                    return {label: this.$t('Бронзовий'), shield: '_bronze'};
            }
        },
    },
    metaInfo() {
        return {
            title: this.title,
            meta: [
                {name: 'description', content: this.description},
                {property: 'og:title', content: this.title},
                {property: 'og:description', content: this.description},
                {name: 'ROBOTS', content: 'INDEX, FOLLOW'}
            ]
        };
    },
    i18n: require('./i18n').default,
}

