import _laxios from '../_laxios';
import Vue from 'vue';

module.exports = {
	state: Object.assign({
		inspectorData: {
			userData: {},
			history: [],
			verifiedChecks: 0,
			rating: {},
			inspectorsCount: 0,
			countLast30: 0,
			archive: null,
			previousYearInspectors: 0,
			previousYearChecks: 0,
			badgesList: []
		},
		count: null,
		projectRating: null,
		successLanding: {},
	}, __INITIAL_STATE__.Inspector),
	actions: {
		sendProjectRating({commit}, {rating, message}) {
			return _laxios
				.setInspectorProjectRating
				.request({data: {rating, message}})
				.then(payload => {
					commit('projectRating', payload);
					return payload;
				});
		},
		putShareBadge({commit}, {action, resonIds}) {
			return _laxios
				.putInspectorHistory
				.request({data: {action, resonIds}})
				.then(payload => {
					return true;
				});
		},
		getProjectRating({commit, getters: {projectRating}, rootGetters}, Cookie) {
			if (projectRating) {
				return Promise.resolve(projectRating);
			}

			let headers = {};
			if (Cookie) {
				headers.Cookie = Cookie;
			}

			return _laxios
				.getInspectorProjectRating
				.request({headers})
				.then(payload => {
					commit('projectRating', payload);
					return payload;
				})
				.catch(err => {
					console.error('ERROR:::_laxios.getInspectorProjectRating', err.message);
					return null;
				});
		},
		getInspectorCount({commit, getters: {count}}) {
			if (count) {
				return Promise.resolve(count);
			}
			return _laxios
				.getInspectorCount
				.request()
				.then(({count}) => count)
				.then(count => {
					commit('count', count);
					return count;
				})
				.catch(error => {
					console.error('get inspector count', error.toString());
				});
		},
		getInspectorData({commit, rootGetters}, Cookie) {
			let headers = {};
			if (Cookie) {
				headers.Cookie = Cookie;
			}

			return _laxios.getInspectorData
				.request({headers})
				.then(payload => {
					commit('inspectorData', {inspectorData: payload});
					return payload;
				})
				.catch(() => rootGetters['Inspector/inspectorData']);
		},
		inspectorSuccessLanding({ commit }, Cookie) {
			let headers = {};
			if (Cookie) {
				headers.Cookie = Cookie;
			}

			return _laxios.inspectorSuccessLanding
				.request({headers})
				.then((data = {}) => {
					commit('setSuccessLandingData', data)
				}).catch((e) => console.log('inspectorSuccessLanding: ', e));
		},
	},
	mutations: {
		inspectorData(state, {inspectorData}) {
			Vue.set(state, 'inspectorData', inspectorData);
		},
		count(state, count) {
			Vue.set(state, 'count', count);
		},
		projectRating(state, rating) {
			Vue.set(state, 'projectRating', rating);
		},
		shareBadge({inspectorData}, payload) {
			const {index} = payload;
			const multiplier = 10;
			inspectorData.badgesList = [
				...inspectorData.badgesList.map(badge => {
					if (badge.index === index) {
						badge.shareBadge = true;
					}
					return badge;
				})
			];
			inspectorData.rating.progress += multiplier;
		},
		updateBadgeModalData({inspectorData}, payload) {
			const {index} = payload;
			inspectorData.badgesList = [
				...inspectorData.badgesList.map(badge => {
					badge.usedByModal = false;
					if (badge.index === index) {
						badge.usedByModal = true;
					}
					return badge;
				})
			];
		},
		setSuccessLandingData(state, data = {}) {
			Vue.set(state, 'successLanding', data);
		}
	},
	getters: {
		inspectorData({inspectorData}) {
			return inspectorData;
		},

		count({count}) {
			return count;
		},
		projectRating({projectRating}) {
			return projectRating;
		},
		badgeModalData({inspectorData}) {
			return inspectorData.badgesList.find(badge => badge.usedByModal);
		},
		getSuccessLandingData({ successLanding }) {
			return successLanding;
		}
	},
	namespaced: true
};
